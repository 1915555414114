import Toast from 'Components/Common/Toast/Toast';
import React from 'react';
import Router from './Router';
import 'styles/main.scss';
const App = () => (
  <>
    <Toast />
    <Router />
  </>
);

export default App;
