export const setProjectAddModal = (projectAddModal) => ({
  type: 'SET_PROJECT_ADD_MODAL',
  payload: projectAddModal,
});

export const setProjectEditModal = (projectEditModal) => ({
  type: 'SET_PROJECT_EDIT_MODAL',
  payload: projectEditModal,
});

export const setProjectUserModal = (userModal) => ({
  type: 'SET_PROJECT_USER_MODAL',
  payload: userModal,
});

export const setProjectDeleteModal = (deleteModal) => ({
  type: 'SET_PROJECT_DELETE_MODAL',
  payload: deleteModal,
});
export const setIsMissingLogsModalOpen = (modal) => ({
  type: 'SET_IS_MISSING_LOGS_MODAL_OPEN',
  payload: modal,
});

export const setEmployeeRoleModal = (modal) => ({
  type: 'SET_EMPLOYEE_ROLE_MODAL',
  payload: modal,
});
