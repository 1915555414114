import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { useSelector } from 'react-redux';
import './CalendarView.scss';
import { formatLoggedTime, getZonedTime } from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import DayLogsView from './DayLogsView';

const CalendarView = () => {
  const { logsViewMode, projectLogsList, fromDateFilter, toDateFilter } =
    useSelector((state) => state);
  const [calendarMonth, setCalendarMonth] = useState(
    new Date(moment().year(), moment().month())
  );
  const [calendarFromMonth, setCalendarFromMonth] = useState('');
  const [calendarToMonth, setCalendarToMonth] = useState('');
  const [monthDifference, setMonthDifference] = useState(
    moment(toDateFilter, 'YYYY-MM-DD').diff(
      moment(fromDateFilter, 'YYYY-MM-DD'),
      'months',
      true
    )
  );
  const [dayLogsModal, setDayLogsModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date(moment()));

  useEffect(() => {
    setCalendarMonth(
      new Date(
        moment(fromDateFilter, 'YYYY-MM-DD').format('YYYY'),
        moment(fromDateFilter, 'YYYY-MM-DD').format('MM') - 1
      )
    );
    setCalendarFromMonth(
      new Date(moment(fromDateFilter, 'YYYY-MM-DD').format('YYYY-MM-DD'))
    );
    setCalendarToMonth(
      new Date(
        logsViewMode === 'THIS_WEEK'
          ? moment().endOf('isoweek').toDate()
          : logsViewMode === 'THIS_MONTH'
          ? moment().endOf('month').toDate()
          : logsViewMode === 'THIS_YEAR'
          ? moment().endOf('year').toDate()
          : moment(toDateFilter, 'YYYY-MM-DD').format('YYYY-MM-DD')
      )
    );
    setMonthDifference(
      moment(toDateFilter, 'YYYY-MM-DD').diff(
        moment(fromDateFilter, 'YYYY-MM-DD'),
        'months',
        true
      )
    );
  }, [logsViewMode, fromDateFilter, toDateFilter]);

  const modifiers = {
    highlighted: {
      from: new Date(moment(fromDateFilter, 'YYYY-MM-DD').format('YYYY-MM-DD')),
      to: new Date(
        logsViewMode === 'THIS_WEEK'
          ? moment().endOf('isoweek').toDate()
          : logsViewMode === 'THIS_MONTH'
          ? moment().endOf('month').toDate()
          : logsViewMode === 'THIS_YEAR'
          ? moment().endOf('year').toDate()
          : moment(toDateFilter, 'YYYY-MM-DD').format('YYYY-MM-DD')
      ),
    },
    loggedToday: projectLogsList
      ?.map(({ date }) => date)
      ?.includes(moment().format('YYYY-MM-DD'))
      ? new Date()
      : '',
  };
  const renderSingleDay = (day) => {
    return (
      <>
        {projectLogsList
          ?.map(({ date }) => date)
          ?.includes(moment(day).format('YYYY-MM-DD')) ? (
          <div className='d-flex justify-content-between'>
            <span>{day.getDate()}</span>
            <img
              className='cursor-pointer'
              alt='...'
              src={require('assets/svg/log-icon.svg').default}
              onClick={() => {
                setDayLogsModal(true);
                setCurrentDate(day);
              }}
            />
          </div>
        ) : (
          <div>
            <span>{day.getDate()}</span>
          </div>
        )}
      </>
    );
  };

  const renderMultipleDay = (day) => {
    const totalLoggedTime = projectLogsList
      ?.filter(({ date }) => date === moment(day).format('YYYY-MM-DD'))
      ?.map(({ hours }) => hours)
      .reduce((total, sum) => total + sum, 0);
    return (
      <div>
        <span>{day.getDate()}</span>
        {projectLogsList
          ?.map(({ date }) => date)
          ?.includes(moment(day).format('YYYY-MM-DD')) ? (
          <div className='weekday-content'>
            {`${
              isNaN(totalLoggedTime)
                ? '-'
                : formatLoggedTime({
                    hours: getZonedTime({
                      type: 'hours',
                      value: totalLoggedTime,
                    }),
                    minutes: getZonedTime({
                      type: 'minutes',
                      value: totalLoggedTime,
                    }),
                  })
            }`}
            <img
              className='cursor-pointer'
              alt='...'
              src={require('assets/svg/log-icon.svg').default}
              onClick={() => {
                setDayLogsModal(true);
                setCurrentDate(day);
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={`${
          monthDifference > 3 ? 'multiple' : 'single'
        }-months-view calendar-view ${
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin'
            : 'user'
        }-calendar`}
      >
        <div className='calendar'>
          <DayPicker
            modifiers={modifiers}
            month={calendarMonth}
            fromMonth={calendarFromMonth}
            toMonth={calendarToMonth}
            selectedDays={projectLogsList?.map(({ date }) =>
              moment(date).toDate()
            )}
            renderDay={
              monthDifference > 3 ? renderSingleDay : renderMultipleDay
            }
            numberOfMonths={monthDifference > 3 ? 6 : 1}
            pagedNavigation
            firstDayOfWeek={1}
          />
        </div>
      </div>

      <CustomModal
        isOpen={dayLogsModal}
        toggle={() => setDayLogsModal(!dayLogsModal)}
        headerText={`Logs for ${moment(currentDate).format('dddd')}, ${moment(
          currentDate
        ).format('LL')}`}
        customSize='lg'
        noButtons={true}
      >
        <DayLogsView logsDate={currentDate} />
      </CustomModal>
    </>
  );
};

export default CalendarView;
