import React from 'react';
import { useSelector } from 'react-redux';

import LogCard from 'Components/Common/LogCard/LogCard';
import './GridView.scss';
const GridView = ({
  pageSize,
  selectedLogs,
  setSelectedLogs,
  editLogHandler,
}) => {
  const { projectLogsList } = useSelector((state) => state);
  return (
    <>
      <div className='log-cards-container'>
        {projectLogsList
          ?.filter((_, index) => index < pageSize)
          ?.map(
            ({
              uid,
              loggedBy,
              date,
              timeStart,
              timeEnd,
              hours,
              worked,
              lateSitting,
              archived,
              tracking,
            }) => (
              <LogCard
                uid={uid}
                loggedBy={loggedBy}
                date={date}
                timeStart={timeStart}
                timeEnd={timeEnd}
                hours={hours}
                worked={worked}
                lateSitting={lateSitting}
                archived={archived}
                tracking={tracking}
                selectedLogs={selectedLogs}
                setSelectedLogs={setSelectedLogs}
                editLogHandler={editLogHandler}
              />
            )
          )}
      </div>
    </>
  );
};

export default GridView;
