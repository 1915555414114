import React from 'react';
import AdminHeader from './Admin/Admin';
import UserHeader from './User/User';

const Header = () => {
  return (
    <>
      {localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin' ? (
        <AdminHeader />
      ) : (
        <UserHeader />
      )}
    </>
  );
};

export default Header;
