export const projectAddModal = (projectAddModal = false, { type, payload }) =>
  type === 'SET_PROJECT_ADD_MODAL' ? payload : projectAddModal;
export const projectEditModal = (projectEditModal = false, { type, payload }) =>
  type === 'SET_PROJECT_EDIT_MODAL' ? payload : projectEditModal;
export const projectUserModal = (userModal = false, { type, payload }) =>
  type === 'SET_PROJECT_USER_MODAL' ? payload : userModal;
export const projectDeleteModal = (deleteModal = false, { type, payload }) =>
  type === 'SET_PROJECT_DELETE_MODAL' ? payload : deleteModal;
export const isMissingModalOpen = (modal = false, { type, payload }) =>
  type === 'SET_IS_MISSING_LOGS_MODAL_OPEN' ? payload : modal;

export const employeeRoleModal = (roleModal = false, { type, payload }) =>
  type === 'SET_EMPLOYEE_ROLE_MODAL' ? payload : roleModal;
