export const setIsProjectEditButtonClicked = (editButton) => ({
  type: 'SET_PROJECT_EDIT_BUTTON_CLICKED',
  payload: editButton,
});

export const setProjectObject = (projectObject) => ({
  type: 'SET_PROJECT_OBJECT',
  payload: projectObject,
});

export const setProjectsList = (projectsList) => ({
  type: 'SET_PROJECTS_LIST',
  payload: projectsList,
});

export const setSelectedProjectId = (projectId) => ({
  type: 'SET_SELECTED_PROJECT_ID',
  payload: projectId,
});

export const setSelectedProjectObject = (project) => ({
  type: 'SET_SELECTED_PROJECT',
  payload: project,
});

export const setSelectedProjectName = (projectName) => ({
  type: 'SET_SELECTED_PROJECT_NAME',
  payload: projectName,
});
export const setProjectTeamArray = (teamArray) => ({
  type: 'SET_PROJECT_TEAM_ARRAY',
  payload: teamArray,
});

export const setTimeLogStartTime = (timeStart = 540) => ({
  type: 'SET_TIME_LOG_START_TIME',
  payload: timeStart,
});
export const setTimeLogEndTime = (timeEnd = 1080) => ({
  type: 'SET_TIME_LOG_END_TIME',
  payload: timeEnd,
});
export const setCustomSliderArray = (sliderArray) => ({
  type: 'SET_CUSTOM_SLIDER_ARRAY',
  payload: sliderArray,
});
export const setWorkedOnWhat = (workedOnWhat) => ({
  type: 'SET_WORKED_ON_WHAT',
  payload: workedOnWhat,
});
export const setReasonForLateSitting = (reasonForLateSitting) => ({
  type: 'SET_REASON_FOR_LATE_SITTING',
  payload: reasonForLateSitting,
});
export const setFromDateFilter = (fromDate) => ({
  type: 'SET_FROM_DATE_FILTER',
  payload: fromDate,
});
export const setToDateFilter = (toDate) => ({
  type: 'SET_TO_DATE_FILTER',
  payload: toDate,
});
export const setValidateFilterDate = (validateDate) => ({
  type: 'SET_VALIDATE_FILTER_DATE',
  payload: validateDate,
});
export const setProjectStartDate = (startDate) => ({
  type: 'SET_PROJECT_START_DATE',
  payload: startDate,
});
export const setProjectEndDate = (endDate) => ({
  type: 'SET_PROJECT_END_DATE',
  payload: endDate,
});
