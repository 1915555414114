import React from 'react';
import './StatCard.scss';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import TimeStatComponent from './TimeStatComponent';
import UserStatComponent from './UserStatComponent';
import LoggedByEmployeeRow from './LoggedEmployeeRow';
const StatCard = ({
  cardTitle,
  hours,
  minutes,
  progressBarValue,
  hoursAllowed,
  userValue,
  userText,
  activeUsersList,
  percentageArrow,
  percentageValue,
  sinceTimeText,
  CustomComponent,
  customClass,
  cardNo,
}) => {
  return (
    <Col lg='6' xl='3' className={customClass}>
      <Card className='card-stats mb-xl-0 mb-4'>
        <CardBody>
          <Row>
            <div className='col pr-0'>
              <CardTitle tag='h5' className='text-camelcase text-muted mb-0'>
                {userText ? (
                  <UserStatComponent
                    userValue={userValue}
                    userText={userText}
                  />
                ) : (
                  cardTitle?.toUpperCase()
                )}
              </CardTitle>
              {userText ? (
                <h5 className='text-camelcase text-muted mt-1 mb-526 '>
                  {cardTitle?.toUpperCase()}
                </h5>
              ) : (
                <TimeStatComponent
                  hours={hours}
                  minutes={minutes}
                  progressBarValue={progressBarValue}
                  hoursAllowed={hoursAllowed}
                  name={cardTitle?.toLowerCase()}
                />
              )}
            </div>
            <Col className='col-auto'>
              <CustomComponent />
            </Col>
          </Row>
          {percentageValue || percentageValue === 0 ? (
            <p className={`mb-0 text-muted`}>
              {(userText && userValue === 0) ||
              (hours === 0 && minutes === 0) ? (
                <span className='text-disabled mr-2'>0%</span>
              ) : percentageArrow === 'up' ? (
                <span className='text-success mr-2'>
                  <i className='fa fa-arrow-up mr-1' />
                  {parseFloat(percentageValue)
                    .toFixed(2)
                    .replace(/\.?0+$/, '')}
                  %
                </span>
              ) : (
                <span className='text-danger mr-2'>
                  <i className='fas fa-arrow-down mr-1' />
                  {parseFloat(percentageValue)
                    .toFixed(2)
                    .replace(/\.?0+$/, '')}
                  %
                </span>
              )}
              <span className='text-nowrap'>{sinceTimeText}</span>
            </p>
          ) : (
            <>
              <div className='card-percentage-loader' />{' '}
            </>
          )}

          <LoggedByEmployeeRow
            loggedEmployeeList={activeUsersList}
            name={
              userText
                ? userText?.split(' ')?.[0]
                : cardTitle?.split(' ')?.[1]
                ? cardTitle?.split(' ')?.[1]
                : cardTitle?.split(' ')?.[0]
            }
            hours={hours}
            activeUsers={activeUsersList?.length}
            cardNo={cardNo}
          />
        </CardBody>
      </Card>
    </Col>
  );
};
export default StatCard;
