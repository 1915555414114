import React, { useState, useEffect } from 'react';
import InputRange from 'react-input-range';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import 'react-input-range/lib/css/index.css';
import './CustomSlider.scss';
import {
  setCustomSliderArray,
  setReasonForLateSitting,
  setTimeLogEndTime,
  setTimeLogStartTime,
  setWorkedOnWhat,
} from 'redux/actions/projects';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomSlider = ({
  max,
  min,
  disabled,
  index,
  workedOnWhatProp,
  multipleLogs,
  sliderManipulateHandler,
  sliderDataChangedHandler,
}) => {
  const dispatch = useDispatch();
  const {
    customSliderArray,
    timeLogStartTime,
    timeLogEndTime,
    workedOnWhat,
    reasonForLateSitting,
    logDate,
    logAddModal,
    logEditModal,
  } = useSelector((state) => state);

  const [value, setValue] = useState({
    min,
    max,
  });
  const [tooltipFlag, setTooltipFlag] = useState(false);
  const setValueHandler = ({ min, max }) => {
    setTooltipFlag(true);
    let maxValue = moment().minutes() + moment().hours() * 60;
    let tempMin, tempMax;
    min < 0 ? (tempMin = 0) : (tempMin = min);
    max > 1430 ? (tempMax = 1430) : (tempMax = max);
    if (
      moment().format('YYYY-MM-DD') === moment(logDate).format('YYYY-MM-DD') &&
      max >= maxValue
    )
      tempMax = maxValue - 1;
    if (tempMax - tempMin >= 10) {
      if (sliderDataChangedHandler) sliderDataChangedHandler(true);
      dispatch(setTimeLogStartTime(tempMin));
      dispatch(setTimeLogEndTime(tempMax));
      setValue({ min: tempMin, max: tempMax });
    }
    setTimeout(() => {
      setTooltipFlag(false);
    }, 3000);
  };
  const deleteSliderHandler = (e, sliderIndex) => {
    e?.preventDefault();
    dispatch(
      setCustomSliderArray(
        customSliderArray?.filter((_, key) => key !== sliderIndex)
      )
    );
    sliderManipulateHandler();
  };

  const addSliderHandler = (e) => {
    e.preventDefault();
    const array = customSliderArray?.slice();
    array?.push({
      min: timeLogStartTime,
      max: timeLogEndTime,
      workedOnWhat: workedOnWhat,
      lateSitting: reasonForLateSitting,
    });
    dispatch(setCustomSliderArray(array));
    dispatch(setWorkedOnWhat(''));
    dispatch(setReasonForLateSitting(''));
    sliderManipulateHandler();
  };
  useEffect(() => {
    if (!disabled && !logEditModal)
      setValueHandler({ min: timeLogStartTime, max: timeLogEndTime });
    setTooltipFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDate]);
  return (
    <>
      <div className='slider-component'>
        <OverlayTrigger
          placement='right-start'
          delay={{ show: 250, hide: 400 }}
          show={
            moment().format('YYYY-MM-DD') ===
              moment(logDate, 'YYYY-MM-DD').format('YYYY-MM-DD') &&
            timeLogEndTime + 1 >= moment().minutes() + moment().hours() * 60 &&
            tooltipFlag &&
            (logAddModal || logEditModal)
          }
          overlay={
            <Tooltip>{'End time should not exceed the current time'}</Tooltip>
          }
        >
          <InputRange
            className='custom-input-range'
            maxValue={1430}
            minValue={0}
            step={10}
            value={value}
            formatLabel={(value) =>
              moment
                .utc()
                .startOf('day')
                .add(value, 'minutes')
                .format('hh:mm A')
            }
            onChange={(value) => setValueHandler(value)}
            disabled={disabled}
            draggableTrack
          />
        </OverlayTrigger>
        {multipleLogs ? (
          <div
            className={`slider-manipulate ${
              disabled ? 'delete-slider' : 'add-slider'
            }`}
          >
            {disabled ? (
              <button onClick={(e) => deleteSliderHandler(e, index)}>
                <i
                  className='fas fa-times-circle fa-lg'
                  title='delete'
                  disabled
                />
              </button>
            ) : (
              <button
                disabled={!workedOnWhat}
                onClick={(e) => addSliderHandler(e)}
              >
                <i className='fas fa-plus-circle fa-lg' title='add' disabled />
              </button>
            )}
          </div>
        ) : (
          ''
        )}

        {workedOnWhatProp ? (
          <label
            id={workedOnWhatProp}
            className='slider-about-label'
            style={{
              marginLeft: `${(value.min / 10) * 0.699301}%`,
              width: `${(value.max - value.min) * 0.0699301}%`,
            }}
            title={workedOnWhatProp}
          >
            {workedOnWhatProp}
          </label>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default CustomSlider;
