import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';

const IntegrationCard = ({ title, text, imgPath }) => {
  return (
    <Card className='text-center'>
      <CardHeader>
        <div className='integration-connect-button'>
          <label className='mb-0 custom-toggle markdown-toogle custom-parent-toggler small'>
            <input type='checkbox' checked={false} />
            <span className='custom-toggle-slider markdown-toogle-slider rounded-circle custom-child-toggler left' />
          </label>
        </div>
        <img
          src={require(`assets/img/${imgPath}.png`).default}
          alt='slack_icon'
          height='46'
        />
      </CardHeader>
      <CardBody className='integration-card-body'>
        <span className='color-admin'>{title}</span>
        <p>{text}</p>
      </CardBody>
      <CardFooter>
        <Button
          className='custom-btn-size integration-outline-btn custom-disabled-btn'
          outline
        >
          Connect{' '}
          <img
            src={require('assets/img/integration_connect_disabled.png').default}
            alt='connect'
            width='16'
          />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default IntegrationCard;
