import React from 'react';

const UserStatComponent = ({ userValue, userText }) => {
  return (
    <>
      {!userValue && userValue !== 0 ? (
        <div className='card-info-loader' />
      ) : (
        <div>
          <div className={`mb-06 ${userValue === 0 ? 'mb-3' : ''}`}>
            {userValue ? (
              <span className='h4 font-weight-bold mb-0'>{userValue}</span>
            ) : (
              ''
            )}

            <span
              className={`h5 ${!userValue ? 'text-disabled' : ''}${
                userValue === 0 ? ' no-active-users' : ''
              }`}
            >
              {` ${userText}`}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default UserStatComponent;
